<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-5">
        What is the element with the lowest atomic number that has a
        <stemble-latex :content="`$\\textcolor{red}{\\textbf{${dState}}}\\textbf{ d-subshell}$`" />
        in its ground state? Write the atomic symbol for that element.
      </p>

      <div class="custom-chemical-symbol-width">
        <chemical-symbol-input
          v-model="inputs.input1"
          :prepend-text="'$\\text{Element:}$'"
          :disabled="!allowEditing"
        />
      </div>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalSymbolInput from '../inputs/ChemicalSymbolInput';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question32',
  components: {
    ChemicalSymbolInput,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        input1: null,
      },
    };
  },
  computed: {
    dPosition() {
      return this.taskState.getValueBySymbol('arrayVariable').content;
    },
    dState() {
      if (this.dPosition.value === 0) {
        return 'half-filled';
      } else if (this.dPosition.value === 1) {
        return 'filled';
      }
      return '';
    },
  },
};
</script>
<style>
.custom-chemical-symbol-width {
  width: 20vw;
}
</style>
